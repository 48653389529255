<template>

    <div v-if="nameOnly">
        <form id="userDetails" class="apform">
            <div v-if="errors.length" class="errorMessages">
                <ul>
                    <li v-for="(error, errorIndex) in errors" v-bind:key="errorIndex">{{ error }}</li>
                </ul>
            </div>
            <div class="flex-container">
                <div>
                    <label for="lastname">Nom</label>
                    <input name="lastname" type="text" tabindex="1" v-model="lastname" placeholder="Nom" required autofocus />
                </div>
                <div>
                    <label for="firstname">Prénom</label>
                    <input name="firstname" type="text" tabindex="3" v-model="firstname" placeholder="Prénom" required/>
                </div>
            </div>
            <div v-if="!isAssistant">
                <label for="publicAccessAuthorize">J'apparais dans l'annuaire</label>
                <input name="publicAccessAuthorize" type="checkbox" tabindex="4" v-model="publicAccessAuthorize" placeholder="visualusation public" required/>
            </div>
            <div class="modal-buttons">
                <button name="cancel" type="cancel"  @click="this.$router.push('/pros/sessions')">Annuler</button>
                <button name="submit" type="submit"  @click="updateUserDetails">Enregistrer</button>
            </div>
        </form>
    </div>
    <div v-else>
        <div class="modal-overlay" style="display: block;" v-if="confirmDeleteUser" @click="hideModal"></div>  
        <div v-if="confirmDeleteUser" class="modal">
            <div class="modal-content">
                <h5>Etes vous sûr de vouloir supprimer votre compte ?</h5>
                <br/>
                <p>Cette opération est irréversible.</p>
                <p>Toutes vos données seront perdues et ne pourront être restaurées.</p>
                <div class="modal-buttons">
                    <button name="cancel" class="cancel" @click="confirmDeleteUser = false">Annuler</button>
                    <button name="submit" @click="deleteAccount" class="button-danger">OK</button>
                </div>
            </div>
        </div>
        <form id="userDetails" class="apform">
            <div>
                <div v-if="logo !== null" class="logo-container">
                    <img v-if="logo !== null" :src="logo" alt="logo entreprise" class="firm-logo"/>
                    <div @click="clearLogo()" class="times-circle-container drop-logo"><font-awesome-icon icon="times-circle" class="times-circle-style"/></div> 
                </div>
                <div v-else>
                    <p>En attente de logo</p>
                </div>
                <div v-if="errors.length" class="errorMessages">
                    <ul>
                        <li v-for="(error, errorIndex) in errors" v-bind:key="errorIndex">{{ error }}</li>
                    </ul>
                </div>
                <div>
                    <div>
                        <div id="change-logo-btn" @click="this.$refs.logoinput.click()"><font-awesome-icon icon="camera-alt" class="camera-alt-style"/>Changer de logo</div>
                        <input name="logo" type="file" accept="image/*" tabindex="6" @change="uploadLogo($event)" text="Changer la photo" style="visibility: hidden;" ref="logoinput"/>
                    </div>
                    <div class="flex-container">
                        <div>
                            <label for="lastname">Nom</label>
                            <input name="lastname" type="text" tabindex="7" v-model="lastname" placeholder="Nom" required autofocus />
                        </div>
                        <div>
                            <label for="firstname">Prénom</label>
                            <input name="firstname" type="text" tabindex="8" v-model="firstname" placeholder="Prénom" required/>
                        </div>
                        <div>
                            <label for="publicAccessAuthorize">J'apparais dans l'annuaire</label>
                            <input name="publicAccessAuthorize" type="checkbox" tabindex="9" v-model="publicAccessAuthorize" placeholder="visualusation public" required/>
                        </div>
                    </div>
                    <div class="flex-container">
                        <input name="individualFirm" type="checkbox" tabindex="5" v-model="individualFirm" placeholder="visualusation public"/>
                        <label for="individualFirm">Entreprise individuelle</label>
                    </div>
                </div>
            </div>
            <div class="flex-container">
                <div>
                    <label for="email">Adresse mail<span class="subtitle-form">(apparaît sur les documents)</span></label>
                    <input name="email" type="text" tabindex="10" v-model="email" placeholder="E-mail" required/>
                </div>
                <div>
                    <label for="phoneNumber">Téléphone</label>
                    <input name="phoneNumber" type="text" tabindex="11" v-model="phoneNumber" placeholder="Téléphone" />
                </div>
            </div>
            <div class="flex-container">
                <div>
                    <label for="firmName">Société</label>
                    <input name="firmName" type="text" tabindex="12" v-model="firmName"  placeholder="Société" required/>
                </div>
                <div>
                    <label for="siren">Siren</label>
                    <input name="siren" type="text" tabindex="13" v-model="siren"  placeholder="SIREN, SIRET..." required/>
                </div>
            </div>
            <div class="flex-container">
                <div class="flex-container">
                    <label for="taxes">Soumis à la tva</label>
                    <input data-cy="user-checkbox-taxes" name="taxes" type="checkbox" tabindex="8" v-model="taxes"/>
                </div>
                <div v-if="taxes===true" class="flex-container taxes-amount-container">
                    <label for="taxesAmount">Montant (en %)</label>
                    <input name="taxesAmount" type="text" tabindex="14" v-model="taxesAmount"/>
                </div>
            </div>
            <div class="flex-container">
                <div>
                    <label for="streetName">Adresse</label>
                    <input name="streetName" type="text" tabindex="15" v-model="streetName" placeholder="Rue" required/>
                </div>
            </div>
            <div class="flex-container">
                <div>
                    <label for="zipCode">Code postal</label>
                    <input name="zipCode" type="text" tabindex="16" v-model="zipCode" placeholder="Code postal" required/>
                </div>
                <div>
                    <label for="city">Ville</label>
                    <input name="city" type="text" tabindex="17" v-model="city" placeholder="Ville" required/>
                </div>
            </div>

            <div>
                <label for="website" class="user-form-label">Site internet</label>
                <input name="website" type="text" tabindex="18" v-model="website" placeholder="Site internet" />
            </div>
            <div>
                <label for="default-price" class="user-form-label">Prix par défaut d'une séance (H.T)</label>
                <input name="default-price" type="text" tabindex="19" v-model="defaultSessionPrice" placeholder="Prix par défaut" />
            </div>
            <div class="flex-container">
                <div>
                    <div>
                        <label for="bank-name" class="user-form-label">Banque</label>
                        <input name="bank-name" type="text" tabindex="20" v-model="bankName" placeholder="Nom de la banque" />
                    </div>
                    <div>
                        <label for="rib" class="user-form-label">RIB</label>
                        <input name="rib" type="text" tabindex="21" v-model="rib" placeholder="Banque Guichet Compte Clé" />
                    </div>
                    <div>
                        <label for="iban" class="user-form-label">IBAN</label>
                        <input name="iban" type="text" tabindex="22" v-model="iban" placeholder="IBAN" />
                    </div>
                    <div>
                        <label for="bic" class="user-form-label">BIC</label>
                        <input name="bic" type="text" tabindex="23" v-model="bic" placeholder="BIC" />
                    </div>
                    <div>
                        <label for="tvaNumber" class="user-form-label">Numéro de TVA</label>
                        <input name="tvaNumber" type="text" tabindex="24" v-model="tvaNumber" placeholder="Numéro de TVA" />
                    </div>
                </div>
                <div v-if="(rib === srcRib && iban === srcIban && bic === srcBic) || updateUserDetailsClicked">
                    <button name="sepa-generation" @click="generateSEPA">Mandat SEPA</button>
                </div>
            </div>
            <div class="deletion-button-container">
                <h3 @click="showDeleteAccount=!showDeleteAccount">Fermer son compte <font-awesome-icon icon="fa-solid fa-caret-right"/></h3>
                <button v-if="showDeleteAccount" @click="confirmDeleteUser = true" type="button" class="deletion-button">Supprimer mon compte</button>
            </div>
            <div class="modal-buttons">
                <button name="cancel" class="cancel" type="cancel"  @click="this.$router.push('/pros/sessions')">Annuler</button>
                <button name="submit" type="submit"  @click="updateUserDetails">Enregistrer</button>
            </div>
        </form>
    </div>
    
</template>

<script>
import { ROLES } from '../../services/roles';

export default {
    // eslint-disable-next-line
	name: 'User',
    data() {
        return {
            email: '',
            firstname: '',
            lastname: '',
            publicAccessAuthorize: true,
            individualFirm: false,
            phoneNumber: '',
            firmName: '',
            siren: '',
            streetName: '',
            city: '',
            zipCode: '',
            website: '',
            defaultSessionPrice: 0,
            confirmDeleteUser: false,
            bankName: '',
            rib: '',
            tvaNumber: '',
            iban: '',
            bic: '',
            logo: null,
            errors: [],
            srcRib: '',
            srcIban: '',
            srcBic: '',
            taxes: true,
            taxesAmount: 20,
            updateUserDetailsClicked: false,
            offerName: '',
            isAssistant : false,
            showDeleteAccount: false,
            nameOnly: false

        }
    },
    methods: {
        changeNameOnly() {
            return this.offerName === 'ULTIME' && (this.$store.getters.roles === ROLES.THERAPIST.value || this.$store.getters.roles === ROLES.ASSISTANT.value)
        },
        clearLogo() {
            this.logo = null;
        },
        readFile(file) {
            const reader = new FileReader();
            //let fileByteArray = [];
            
            reader.readAsDataURL(file);
            reader.onload = e => {
                this.logo = e.target.result;
            };
        },
        uploadLogo(event) {
            this.logo = this.readFile(event.target.files[0]);
        },
        getUserDetailsWithFirm() {
            this.$store.dispatch({
				type: 'getUserDetailsWithFirm',
			}).then(userDetails => {
                this.email = userDetails.email;
                this.firstname = userDetails.firstname;
                this.lastname = userDetails.lastname;
                this.publicAccessAuthorize = userDetails.publicAccessAuthorize;
                this.individualFirm = userDetails.individualFirm;
                this.phoneNumber = userDetails.phoneNumber;
                this.defaultSessionPrice = userDetails.defaultSessionPrice;
                if (userDetails.firm) {
                    this.firmName = userDetails.firm.name;
                    this.siren = userDetails.firm.siren;
                    this.streetName = userDetails.firm.address.streetName;
                    this.city = userDetails.firm.address.city;
                    this.zipCode = userDetails.firm.address.zipCode;
                    this.website = userDetails.firm.websiteURL;
                }
                this.bankName = userDetails.bankName;
                this.rib = userDetails.rib;
                this.tvaNumber = userDetails.tvaNumber;
                this.iban = userDetails.iban;
                this.bic = userDetails.bic;
                this.logo = userDetails.logo;
                this.srcBic = userDetails.bic;
                this.srcIban = userDetails.iban;
                this.srcRib = userDetails.rib;

                this.taxes = userDetails.taxes ? userDetails.taxes : false;
                if (this.taxes)
                    this.taxesAmount = userDetails.taxesAmount
                this.offerName = userDetails.tenant.offer;
                this.nameOnly = this.changeNameOnly();
            });
            this.isAssistant = this.$store.getters.roles === ROLES.ASSISTANT.value;
        },
        formIsValid() {
            this.errors = [];
            if (this.lastname === '' || this.lastname === null)
                this.errors.push('Nom de famille obligatoire')

            if (this.changeNameOnly() === false) {
                if (this.firstname === '' || this.firstname === null)
                    this.errors.push('Prénom obligatoire')                
                if (this.firmName === '')
                    this.errors.push("Nom de l'entreprise obligatoire")
                if (this.siren === '')
                    this.errors.push('Numéro de SIREN obligatoire')
                if (this.phoneNumber === '' || (this.phoneNumber && !this.phoneNumber.match("^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$")))
                    this.errors.push('Format Numéro de téléphone manquant ou incorrect')
                
                if (this.streetName === '' || this.city  === '' || this.zipCode === '')
                    this.errors.push('Adresse (rue, code postal, ville) obligatoire')
            }
            return this.errors.length === 0;
        },
        updateUserDetails(e) {
            e.preventDefault();
            if (!this.formIsValid())
                return
            
            this.$store.dispatch({
                type: 'updateUserDetails',
                user: {
                    email: this.email, 
                    firstname: this.firstname,
                    lastname: this.lastname,
                    publicAccessAuthorize: this.publicAccessAuthorize,
                    individualFirm: this.individualFirm,
                    phoneNumber: this.phoneNumber,
                    defaultSessionPrice: this.defaultSessionPrice,
                    firm: {
                        name: this.firmName,
                        siren: this.siren,
                        websiteURL: this.website,
                        address: {
                            streetName: this.streetName,
                            city: this.city,
                            zipCode: this.zipCode 
                        }
                    },
                    bankName: this.bankName,
                    rib: this.rib,
                    tvaNumber: this.tvaNumber,
                    iban: this.iban,
                    bic: this.bic,
                    logo: this.logo,
                    taxes: this.taxes,
                    taxesAmount: this.taxesAmount,
                    address: {
                        streetName: this.streetName,
                        city: this.city,
                        zipCode: this.zipCode 
                    }
                }
            }).then((response) => {
                this.$toast.success('Informations profil mises à jour')
                this.logo = response.logo;
                this.updateUserDetailsClicked = true;
            }).catch ((e) => {
                let errorCode = e.response.data.errorCode;
                if (errorCode === 'E110')
                    this.errors.push('Informations entreprise non remplies')
                if (errorCode === 'E116')
                    this.errors.push('Numéro de téléphone incorrect');
            })
        },
        deleteAccount(e) {
            e.preventDefault();
            this.$store.dispatch({
                type: 'deleteUser'
            }).then(() => {
                this.$router.push('/');
                this.$toast.success('Votre compte a été correctement supprimé. A bientôt')
            }).catch(() => {
                this.$toast.error('Erreur lors de la suppression du compte.');
            })

        },
        generateSEPA(e) {
            e.preventDefault();
            this.$store.dispatch({
                type: 'generateSEPA'
            }).then((response) => {
                let docUrl = document.createElement('a');
				docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }));
				docUrl.download="sepa.pdf";
				docUrl.click();
            }).catch(() => {
                this.$toast.warning('Problème lors de la génération du document.');
            })
        }
    },
    mounted() {
		this.getUserDetailsWithFirm();
	}
}
</script>

<style scoped>
.user-form-label {
    width: 15rem;
}

.drop-logo {
    position: relative;
    top: 4rem;
    right: 2rem;
    font-size: 22px;
}

#change-logo-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    max-width: 10rem;
    font-size: 14px;
}
.camera-alt-style {
    font-size: 20px;
    margin-right: 5px;
}

.firm-logo {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
}

.logo-container {
    display: flex;
}

.taxes-amount-container {
    margin-left: 5rem;
}

.taxes-amount-container input {
    width: 2rem !important;
}
</style>