import axios from 'axios';
import authHeaders from '../services/auth-header';
import * as types from './mutation-types';
const PATIENTS_URL = process.env.VUE_APP_BASE_AP_URL+`/patients`;

export const initPatients = ({ commit }) => {
	let patients = [];
	return axios.get(PATIENTS_URL, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          patients.push(...response.data);
		commit(types.INIT_PATIENTS, patients);
		return Promise.resolve();
      }
	})
	.catch(function (error) {
		if (error.response.data.errorCode === 'E123') {
			commit(types.PAYMENT_NEEDED);
		}
		return Promise.reject(error.response);
	});
};

export const addPatient = async ({ commit}, event ) => {
	return axios.post(PATIENTS_URL, event.patient, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
            commit(types.PATIENT_CREATED, response.data);
            return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.PATIENT_NOT_CREATED);
        return Promise.reject(error.response);
	});

};

export const updatePatient = async ({ commit }, event) => {
	return axios.put(`${PATIENTS_URL}/${event.patient.id}`, event.patient, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
			commit(types.PATIENT_UPDATED, response.data);
			return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.PATIENT_UPDATED_ERROR, error);
		return Promise.reject(error.response);
	});
};

export const resetPatient = ({commit}) => {
	commit(types.RESET_PATIENT, {});
}

export const getPatientDetails = async ({ commit }, event) => {
	return axios.get(`${PATIENTS_URL}/${event.id}/details`,  { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          commit(types.GET_PATIENT, response.data);
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      commit(types.GET_PATIENT_ERROR, error);
      return Promise.reject(error.reponse);
	});
}

export const getSessionsByPatientId = async ({ commit }, event) => {
	return axios.get(`${PATIENTS_URL}/${event.sessionsDetails.patientId}/sessions?startDate=${event.sessionsDetails.startDate}&endDate=${event.sessionsDetails.endDate}`, { headers: { 'Authorization':authHeaders() }})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
		commit(types.GET_PATIENT_ERROR, event.sessionsDetails.patientId);  
		return Promise.reject(error.response);
	});
}

export const deletePatient = async ({ commit }, event) => {
	return axios.delete(`${PATIENTS_URL}/${event.id}`, { headers: { 'Authorization':authHeaders()}})
	.then((response) => {
        commit(types.PATIENT_REMOVED, event.id);
		return Promise.resolve(response.data);
	})
	.catch((error) => {
		commit(types.PATIENT_REMOVED_ERROR, error);
		return Promise.reject(error.response);
	});
};


export const getFirms = async ({commit}) => {
	return axios.get(`${PATIENTS_URL}?type=FIRM`, { headers: { 'Authorization':authHeaders() }})
	.then(response => {
      if(response.data) {
          commit(types.GET_FIRMS, '');
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error.response);
	});
}
