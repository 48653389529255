import axios from 'axios';
import authHeaders from '../services/auth-header';
import * as types from './mutation-types';

const USER_URL = process.env.VUE_APP_BASE_AP_URL+`/user`;
const ASK_DEMO_URL = process.env.VUE_APP_BASE_AP_URL+`/ask-demo`;

export const loadStoredState = ({commit}) => {
	if (localStorage.getItem('user') !== null) {
		try {
			commit(types.GET_USER_DETAILS, JSON.parse(localStorage.getItem('user')))
		} catch (e) {
			return;
		}
	}
}

export const askForDemo = async ({commit}, event) => {
	return axios.post(`${ASK_DEMO_URL}`, event.newEntry)
	.then(response => {
		if (response.data) {
			commit(types.ASK_DEMO, response.data);
			return Promise.resolve(response.data);
		}
	})
	.catch(function (error) {
		return Promise.reject(error);
	});
};

export const updateUserDetails = async ({ commit }, event) => {
	return axios.put(`${USER_URL}`, event.user, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
            commit(types.USER_UPDATED, response.data);
            return Promise.resolve(response.data);
        }
	})
	.catch(error => {
		commit(types.USER_UPDATED_ERROR);
        return Promise.reject(error);
	});
};

export const getUserDetails = async ({ commit }, index) => {
	if (localStorage.getItem('user') === null || JSON.parse(localStorage.getItem('user')).tenant === undefined) {
		return axios.get(`${USER_URL}`,  { headers: { 'Authorization':authHeaders()}})
		.then(response => {
		if(response.data) {
			localStorage.setItem('user', JSON.stringify(response.data))
			commit(types.GET_USER_DETAILS, response.data);
			return Promise.resolve(response.data);
		}
		})
		.catch(error => {
		commit(types.GET_USER_DETAILS_ERROR, index);
		return Promise.reject(error.response);
		});
	} else
		return Promise.resolve(JSON.parse(localStorage.getItem('user')))
};

export const getUserDetailsWithFirm = async ({ commit }, index) => {
	if (localStorage.getItem('jwtToken') !== null) {
		return axios.get(`${USER_URL}/details`,  { headers: { 'Authorization':authHeaders()}})
		.then(response => {
		if(response.data) {
			commit(types.GET_USER_DETAILS_WITH_FIRM, response.data);
			return Promise.resolve(response.data);
		}
		})
		.catch(error =>  {
			commit(types.GET_USER_DETAILS_WITH_FIRM_ERROR, index);
			return Promise.reject(error.response);
		});
	}
};

export const getUserDetailsWithFirmWithoutLogo = async ({ commit }, index) => {
	if (localStorage.getItem('jwtToken') !== null) {
		return axios.get(`${USER_URL}/details?logo=false`,  { headers: { 'Authorization':authHeaders()}})
		.then(response => {
		if(response.data) {
			commit(types.GET_USER_DETAILS_WITH_FIRM, response.data);
			return Promise.resolve(response.data);
		}
		})
		.catch(error =>  {
			commit(types.GET_USER_DETAILS_WITH_FIRM_ERROR, index);
			return Promise.reject(error.response);
		});
	}
};

export const getTeam = async ({ commit }, index) => {
	return axios.get(`${USER_URL}/team`,  { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          commit(types.GET_TEAM, response.data);
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      commit(types.GET_TEAM_ERROR, index);
      return Promise.reject(error.response);
	});
};

export const deleteUser = async ({commit}) => {
    return axios.delete(USER_URL, { headers: { 'Authorization':authHeaders() }})
    .then(response => {
        if(response.status === 204) {
          commit(types.USER_REMOVED, response.data);
          return Promise.resolve();
        }
      })
      .catch(error => {
          commit(types.USER_REMOVED_ERROR, error);
          return Promise.reject(error);
      })
}

export const getUserActivities = async ({commit}, event) => {
	let calledURL = `${USER_URL}/activity`;
	if(event.askedInfos.startDate !== null && event.askedInfos.endDate !== null)
		calledURL = `${calledURL}?startDate=${event.askedInfos.startDate}&endDate=${event.askedInfos.endDate}`;

	return axios.get(`${calledURL}`, { headers: { 'Authorization':authHeaders()}})
	.then((response) => {
		commit(types.GET_USER_ACTIVITIES, response.data);
		return Promise.resolve(response.data);
	}).catch((error) => {
		return Promise.reject(error);
	});
};

export const saveAndInvite = async ({commit}, event) => {
	return axios.post(`${USER_URL}/team/members`, event.member, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			commit(types.SAVE_AND_INVITE, response.data);
			return Promise.resolve(response.data);
		}
	})
	.catch(function (error) {
		return Promise.reject(error);
	});
};

export const updateAuthorizations = async ({commit}, event) => {
	return axios.put(`${USER_URL}/team/members`, event.member, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			commit(types.SAVE_AND_INVITE, response.data);
			return Promise.resolve(response.data);
		}
	})
	.catch(function (error) {
		return Promise.reject(error);
	});
};

export const deleteMember = async ({commit}, event) => {
	return axios.delete(`${USER_URL}/team/members/${event.id}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			commit(types.SAVE_AND_INVITE, response.data);
			return Promise.resolve(response.data);
		}
	})
	.catch(function (error) {
		return Promise.reject(error);
	});
};

export const getMemberDetails = async ({commit}, event) => {
	return axios.get(`${USER_URL}/team/members/${event.id}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			commit(types.GET_MEMBER, response.data);
			return Promise.resolve(response.data);
		}
	})
	.catch(function (error) {
		return Promise.reject(error);
	});
};

export const generateSEPA = async() => {
	return axios.post(`${USER_URL}/sepa`, null, {responseType: 'arraybuffer', headers : { 'Authorization':authHeaders()}})
	.then( response => {
		return Promise.resolve(response.data);
	})
	.catch((error) => {
		return Promise.reject(error);
	})
}